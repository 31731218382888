:root {
  --brand-primary-pure: #0096ff;
  --brand-primary-medium: #3aafff;
  --brand-primary-pale: #e6f4ff;
  --analogue-primary-pure: #00a832;
  --analogue-primary-medium: #3bbf63;
  --analogue-primary-pale: #e6f6ea;
  --analogue-secondary-pure: #813af3;
  --analogue-secondary-medium: #8c58e0;
  --analogue-secondary-pale: #f2ebfd;
  --contrast-primary-pure: #ff6a00;
  --contrast-primary-medium: #ff9036;
  --contrast-primary-pale: #fff0e5;
  --contrast-secondary-pure: #e60037;
  --contrast-secondary-medium: #ef3f68;
  --contrast-secondary-pale: #fde6eb;
  --neutral-dark-pure: #191f28;
  --neutral-dark-medium: #4c576c;
  --neutral-dark-pale: #848c9b;
  --neutral-light-pure: #dde0e4;
  --neutral-light-medium: #f5f7f8;
  --neutral-light-pale: #fff;
  --support-info-pure: #0096ff;
  --support-info-medium: #3aafff;
  --support-info-pale: #e6f4ff;
  --support-success-pure: #00a832;
  --support-success-medium: #3bbf63;
  --support-success-pale: #e6f6ea;
  --support-warning-pure: #ff6a00;
  --support-warning-medium: #ff9036;
  --support-warning-pale: #fff0e5;
  --support-danger-pure: #e60037;
  --support-danger-medium: #ef3f68;
  --support-danger-pale: #fde6eb;
  --font-family: "Open Sans", sans-serif;
  --font-super: 3.75rem;
  --font-big: 2.625rem;
  --font-xxlarge: 2.25rem;
  --font-xlarge: 1.75rem;
  --font-large: 1.5rem;
  --font-medium: 1.25rem;
  --font-under-medium: 1.125rem;
  --font-base: 1rem;
  --font-small: 0.875rem;
  --font-tiny: 0.75rem;
  --font-micro: 0.625rem;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-extrabold: 800;
  --spacing-small-1: 0.125rem;
  --spacing-small-2: 0.25rem;
  --spacing-small-3: 0.5rem;
  --spacing-small-4: 0.75rem;
  --spacing-small-5: 1rem;
  --spacing-small-6: 1.5rem;
  --spacing-regular-1: 2rem;
  --spacing-regular-2: 2.5rem;
  --spacing-regular-3: 3rem;
  --spacing-regular-4: 3.5rem;
  --spacing-regular-5: 4rem;
  --spacing-big-1: 4.5rem;
  --spacing-big-2: 5rem;
  --spacing-big-3: 5.5rem;
  --spacing-big-4: 6rem;
  --spacing-large-1: 6.5rem;
  --spacing-large-2: 7rem;
  --spacing-large-3: 7.5rem;
  --spacing-large-4: 8rem;
  --spacing-xlarge-1: 8.5rem;
  --spacing-xlarge-2: 9rem;
  --spacing-xlarge-3: 9.5rem;
  --spacing-xlarge-4: 10rem;
  --shadow-10: 0px 1px 2px rgba(25, 31, 40, 0.15);
  --shadow-20: 0px 2px 10px rgba(25, 31, 40, 0.1);
  --shadow-30: 0px 6px 24px rgba(25, 31, 40, 0.15);
  --shadow-40: 0px 20px 36px rgba(25, 31, 40, 0.2);
  --radius-micro: 0.25rem;
  --radius-small: 0.5rem;
  --radius-medium: 0.75rem;
  --radius-large: 1rem;
  --radius-xlarge: 1.5rem;
  --radius-full: 50%;
  --icon-small: 1rem;
  --icon-base: 1.5rem;
  --icon-medium: 2rem;
  --icon-large: 2.5rem;
}

body {
  font-family: var(--font-family);
}